/* Below animations are fore modal created using React-Modal */
@import "~react-image-gallery/styles/css/image-gallery.css";

.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.image-gallery-image {
    object-fit: cover !important;
    background-color: #1a202c !important;
}

.image-gallery {
    background-color: #1a202c !important;
}

#vid {
    width: 100vw;
    object-fit: cover;
    height: 75vh;
    z-index: -100;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#hero-container {
    height: 75vh;
}

#primary-button-disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    height: 100vh;
}

.corner-ribbon {
    width: 10rem;
    background: #e43;
    position: absolute;
    top: 1rem;
    left: -25px;
    text-align: center;
    line-height: 2rem;
    letter-spacing: 1px;
    color: #f0f0f0;
    font-weight: bolder;
    transform: skewX(-12deg);
    --transform-skew-x: -12deg;
    box-shadow: 0.1rem 0.1rem #e2e8f0;

}

/* Custom styles */

.corner-ribbon.sticky {
    position: fixed;
}

.corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

.corner-ribbon.top-right {
    top: 1rem;
    right: -0.5rem;
    left: auto;
    /*transform: rotate(45deg);*/
    /*-webkit-transform: rotate(45deg);*/
}

.corner-ribbon.bottom-left {
    top: auto;
    bottom: 25px;
    left: -50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right {
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

/* Colors */

.corner-ribbon.white {
    background: #f0f0f0;
    color: #555;
}

.corner-ribbon.black {
    background: #333;
}

.corner-ribbon.grey {
    background: #999;
}

.corner-ribbon.blue {
    background: #39d;
}

.corner-ribbon.green {
    background: #2c7;
}

.corner-ribbon.turquoise {
    background: #1b9;
}

.corner-ribbon.purple {
    background: #95b;
}

.corner-ribbon.red {
    background: #e43;
}

.corner-ribbon.orange {
    background: #e82;
}

.corner-ribbon.yellow {
    background: #ec0;
}

.link-card {
    display: contents;
}